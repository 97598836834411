import AddIcon from './add.svg?react'
import ArrowBack from './arrow-back.svg?react'
import AutoAwesomeIcon from './auto-awesome.svg?react'
import AutoMode from './auto-mode.svg?react'
import BackHandIcon from './back-hand.svg?react'
import BlockIcon from './block.svg?react'
import CallIcon from './call.svg?react'
import CameraAltIcon from './camera-alt.svg?react'
import ChatBubbleOutlineIcon from './chat-bubble-outline.svg?react'
import ChatIcon from './chat.svg?react'
import CheckIcon from './check.svg?react'
import CloseIcon from './close.svg?react'
import ContactsIcon from './contacts.svg?react'
import CopyContentIcon from './copy-content.svg?react'
import DialpadIcon from './dialpad.svg?react'
import ExpandLessIcon from './expand-less.svg?react'
import ExpandMoreIcon from './expand-more.svg?react'
import FavoriteBorderIcon from './favorite-border.svg?react'
import GridOnIcon from './grid-on.svg?react'
import HeadphonesIcon from './headphones.svg?react'
import HistoryIcon from './history.svg?react'
import KeyBoardLeftArrowIcon from './keyboard-left-arrow.svg?react'
import KeyBoardRightArrowIcon from './keyboard-right-arrow.svg?react'
import ListIcon from './list.svg?react'
import LockIcon from './lock.svg?react'
import LoopIcon from './loop.svg?react'
import MicIcon from './mic.svg?react'
import MicOffIcon from './mic-off.svg?react'
import MicOff2Icon from './mic-off-2.svg?react'
import MissedVideoCallIcon from './missed-video-call.svg?react'
import MoreHorizIcon from './more-horiz.svg?react'
import NavigationIcon from './navigation.svg?react'
import NotificationsNoneIcon from './notifications-none.svg?react'
import PersonPinIcon from './person-pin.svg?react'
import PhoneCallbackIcon from './phone-callback.svg?react'
import PhoneDisabledIcon from './phone-disabled.svg?react'
import PlayCircleFilledIcon from './play-circle-filled.svg?react'
import ProgressActivityFilledIcon from './progress-activity.svg?react'
import RaisedHandIcon from './raised-hand.svg?react'
import RefreshIcon from './refresh.svg?react'
import RoomsIcon from './rooms.svg?react'
import ScreenShareIcon from './screen-share.svg?react'
import SearchIcon from './search.svg?react'
import SendIcon from './send.svg?react'
import SettingsIcon from './settings.svg?react'
import ShareIcon from './share.svg?react'
import SofaOutline1Icon from './sofa-outline-1.svg?react'
import SortByAlphaIcon from './sort-by-alpha.svg?react'
import UnlockIcon from './unlock.svg?react'
import VideocamIcon from './videocam.svg?react'
import VideocamOffIcon from './videocam-off.svg?react'
import ViewSidebarIcon from './view-sidebar.svg?react'
import Voicemail1Icon from './voicemail-1.svg?react'
import WarningIcon from './warning.svg?react'

export const MATERIAL_ICON_MAP = {
  'add': AddIcon,
  'arrow-back': ArrowBack,
  'auto-awesome': AutoAwesomeIcon,
  'auto-mode': AutoMode,
  'back-hand': BackHandIcon,
  'block': BlockIcon,
  'call': CallIcon,
  'camera-alt': CameraAltIcon,
  'chat': ChatIcon,
  'chat-bubble-outline': ChatBubbleOutlineIcon,
  'check': CheckIcon,
  'close': CloseIcon,
  'contacts': ContactsIcon,
  'copy-content': CopyContentIcon,
  'dialpad': DialpadIcon,
  'expand-less': ExpandLessIcon,
  'expand-more': ExpandMoreIcon,
  'favorite-border': FavoriteBorderIcon,
  'grid-on': GridOnIcon,
  'headphones': HeadphonesIcon,
  'history': HistoryIcon,
  'keyboard-left-arrow': KeyBoardLeftArrowIcon,
  'keyboard-right-arrow': KeyBoardRightArrowIcon,
  'list': ListIcon,
  'lock': LockIcon,
  'loop': LoopIcon,
  'mic': MicIcon,
  'mic-off': MicOffIcon,
  'mic-off-2': MicOff2Icon,
  'missed-video-call': MissedVideoCallIcon,
  'more-horiz': MoreHorizIcon,
  'navigation': NavigationIcon,
  'notifications-none': NotificationsNoneIcon,
  'person-pin': PersonPinIcon,
  'phone-callback': PhoneCallbackIcon,
  'phone-disabled': PhoneDisabledIcon,
  'play-circle-filled': PlayCircleFilledIcon,
  'progress-activity': ProgressActivityFilledIcon,
  'raised-hand': RaisedHandIcon,
  'refresh': RefreshIcon,
  'rooms': RoomsIcon,
  'screen-share': ScreenShareIcon,
  'search': SearchIcon,
  'send': SendIcon,
  'settings': SettingsIcon,
  'share': ShareIcon,
  'sofa-outline-1': SofaOutline1Icon,
  'sort-by-alpha': SortByAlphaIcon,
  'unlock': UnlockIcon,
  'videocam': VideocamIcon,
  'videocam-off': VideocamOffIcon,
  'view-sidebar': ViewSidebarIcon,
  'voicemail-1': Voicemail1Icon,
  'warning': WarningIcon,
} as const

export type IconName = keyof typeof MATERIAL_ICON_MAP
