import { useRef } from 'react'
import type { PropsWithChildren } from 'react'
import { Route } from '@/routes/_auth.room.$context.$name'
import { PanelRoom } from '@/components/PanelRoom'
import { RoomFooter } from '@/components/RoomFooter'
import { RoomHeader } from '@/components/RoomHeader'
import { useRoomStore } from '@/stores/room'
import { useUiStore } from '@/stores/ui'

type LayoutRoomProps = PropsWithChildren

export const LayoutRoom = ({ children }: LayoutRoomProps) => {
  const { redirect } = Route.useSearch()
  const callViewRef = useRef<HTMLDivElement>(null)
  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  return (
    <>
      {displayRoomPanel ? (
        <PanelRoom containerElement={callViewRef.current} />
      ) : null}
      <div className="w-svh flex h-svh flex-col justify-between overflow-hidden bg-black">
        <div className="h-room-header w-full shrink-0 bg-primary px-9">
          <RoomHeader />
        </div>
        <div className="w-full flex-1 overflow-hidden" ref={callViewRef}>
          {children}
        </div>
        {isAudioOnly ? null : (
          <div className="z-10 flex h-room-footer w-full shrink-0 items-center justify-center bg-primary">
            <RoomFooter redirect={redirect} />
          </div>
        )}
      </div>
    </>
  )
}
