import type { ButtonProps } from '@/components/base/button'
import { Button } from '@/components/base/button'
import { Icon, type IconTagName } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import type { PropsWithChildren } from 'react'

interface ButtonBaseRoomProps extends ButtonProps {
  iconClassName?: string
  isActive?: boolean
  tag: IconTagName
}

const ButtonBaseRoom = ({
  children,
  className,
  disabled = false,
  iconClassName,
  isActive = false,
  tag,
  ...props
}: PropsWithChildren<ButtonBaseRoomProps>) => {
  // aria pressed is undefined if disabled (meaning cannot be pressed)
  const isPressed = disabled ? undefined : isActive

  return (
    <Button
      aria-pressed={isPressed}
      disabled={disabled}
      className={cn('gap-y-1', isActive && 'gradient', className)}
      {...props}
    >
      <div className={iconClassName}>
        <Icon tag={tag} size="xl" />
      </div>
      <div className="text-xs">{children}</div>
    </Button>
  )
}

export { ButtonBaseRoom }
export type { ButtonBaseRoomProps }
