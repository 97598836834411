// Stores
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { FEATURES } from '@/stores/features'

// Components
import { Feature } from '@/components/Feature'

// Types
import { TEST_IDS } from '@/helpers/constants'
import { useUserStore } from '@/stores/user'
import { LinkLeave } from '@/components/LinkLeave'
import type { RedirectPath } from '@/helpers/types'
import { ButtonVideoRoom } from '@/components/ButtonVideoRoom'

interface RoomFooterProps {
  redirect?: RedirectPath | undefined
}

export const RoomFooter = ({ redirect }: RoomFooterProps) => {
  const handRaised = useRoomStore(state => state.handRaised)
  const memberState = useRoomStore(state => state.memberState)
  const audioMuted = useRoomStore(state => state.audioMuted)
  const videoMuted = useRoomStore(state => state.videoMuted)
  const sharingScreen = useRoomStore(state => state.sharingScreen)
  const isMobile = useUserStore(state => state.isMobile)

  const {
    audioMuteHandler,
    handRaiseHandler,
    videoMuteHandler,
    screenShareHandler,
  } = useRoomStoreActions()

  const memberJoined = memberState === 'joined'

  return (
    <div
      className="relative flex justify-center gap-x-14"
      data-testid={TEST_IDS.ROOM_FOOTER}
      role="toolbar"
    >
      <div className="flex items-center justify-center gap-x-2 text-neutral-light">
        <ButtonVideoRoom
          disabled={!memberJoined}
          isActive={!audioMuted}
          onClick={audioMuteHandler}
          tag={!memberJoined ? 'mic-off-2' : 'mic'}
        >
          Microphone
        </ButtonVideoRoom>
        <ButtonVideoRoom
          disabled={!memberJoined}
          isActive={!videoMuted}
          onClick={videoMuteHandler}
          tag={!memberJoined ? 'videocam-off' : 'videocam'}
        >
          Video
        </ButtonVideoRoom>
        <ButtonVideoRoom disabled isActive tag="headphones">
          Audio
        </ButtonVideoRoom>
      </div>
      <LinkLeave className="-translate-y-7" to={redirect}>
        Leave
      </LinkLeave>
      <div className="flex items-center justify-center gap-x-2">
        <Feature name={FEATURES.ROOM_RAISE_HAND}>
          <ButtonVideoRoom
            disabled={!memberJoined}
            isActive={handRaised}
            onClick={handRaiseHandler}
            tag="back-hand"
          >
            Raise Hand
          </ButtonVideoRoom>
        </Feature>
        {!isMobile && (
          <Feature name={FEATURES.ROOM_SHARE_SCREEN}>
            <ButtonVideoRoom
              disabled={!memberJoined}
              isActive={sharingScreen}
              onClick={screenShareHandler}
              tag="screen-share"
            >
              Share Screen
            </ButtonVideoRoom>
          </Feature>
        )}
        <Feature name={FEATURES.ROOM_DO_NOT_DISTURB}>
          <ButtonVideoRoom disabled tag="block">
            DND
          </ButtonVideoRoom>
        </Feature>
      </div>
    </div>
  )
}
