import { Button } from '@/components/base/button'
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/base/card'
import type { IconTagName } from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'

interface BaseCardLoadingProps {
  cardDescription?: string
  cardTitle?: string
  open: boolean
  tag?: IconTagName
}

type CardLoadingPropsWithConfirm = BaseCardLoadingProps & {
  confirmLabel: string
  onConfirm: () => void
}

type CardLoadingPropsWithoutConfirm = BaseCardLoadingProps & {
  confirmLabel?: never
  onConfirm?: never
}

export type CardLoadingProps =
  | CardLoadingPropsWithConfirm
  | CardLoadingPropsWithoutConfirm

export const CardLoading = ({
  cardDescription = '',
  cardTitle = 'Loading',
  confirmLabel,
  onConfirm,
  open,
  tag = 'auto-mode',
}: CardLoadingProps) => {
  // hide the loader
  if (!open) {
    return null
  }

  return (
    <Card className="flex min-h-dialog w-full max-w-dialog flex-col items-center justify-around gap-8 overflow-hidden rounded-lg border bg-background px-4 py-8 shadow-lg">
      <CardHeader className="flex flex-col gap-y-8 space-y-0 p-0 text-center">
        <div className="flex flex-col items-center justify-center gap-y-8">
          <Icon className="h-20 w-20 animate-spin fill-muted" tag={tag} />
          <CardTitle className="text-3xl font-semibold tracking-normal text-muted">
            {cardTitle}
          </CardTitle>
        </div>
        {cardDescription && (
          <CardDescription className="px-4 text-center font-montserrat text-sm font-normal text-muted">
            {cardDescription}
          </CardDescription>
        )}
      </CardHeader>
      {confirmLabel && (
        <CardFooter className="flex min-w-[150px] flex-col gap-y-2 p-0">
          <Button onClick={onConfirm} variant="gradient">
            {confirmLabel}
          </Button>
        </CardFooter>
      )}
    </Card>
  )
}
