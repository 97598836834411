import { Feature } from '@/components/Feature'
import { INCOMING_CALL_CONTEXT } from '@/helpers/constants'
import { useCallStore } from '@/stores/call'
import { FEATURES } from '@/stores/features'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUiStoreActions } from '@/stores/ui'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { useParams, useRouter, useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useRef } from 'react'
import { MemberOverlays } from '../MemberOverlays'
import { cn } from '@/helpers/utils'
import { AudioCallControls } from '../AudioCallControls'
import { CardLoading } from '@/components/CardLoading'
import type { ChannelType, RedirectPath } from '@/helpers/types'

interface PathParams {
  context: string
  name: string
}
interface SearchParams {
  channel?: ChannelType
  redirect: RedirectPath
}

export const RoomView = () => {
  const rootMcuRef = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const { context, name } = useParams({ strict: false }) as PathParams
  const { channel, redirect } = useSearch({ strict: false }) as SearchParams


  const lastRoomName = useRef<string | null>(null)

  const currentNotification = useCallStore(state => state.currentNotification)


  const memberState = useRoomStore(state => state.memberState)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  const { joinRoom, startRoomSession } = useRoomStoreActions()

  const { hideErrorDialog, setErrorDialogContent, showErrorDialog } =
    useUiStoreActions()
  const {
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
  } = useUserSettingsStoreActions()

  const handleErrorDialog = useCallback(() => {
    const closeAndNavigate = () => {
      hideErrorDialog()
      void router?.navigate({ to: redirect || '/recent' })
    }

    // set the error dialog content
    setErrorDialogContent({
      confirmLabel: 'OK',
      description:
        'There was a problem when joining the room. Please try again.',
      onConfirm: closeAndNavigate,
      title: 'Error',
    })

    // show the error dialog
    showErrorDialog()
  }, [
    setErrorDialogContent,
    hideErrorDialog,
    showErrorDialog,
    router,
    redirect,
  ])

  const joinThisRoom = useCallback(
    async (context: string) => {
      if (rootMcuRef.current === null) {
        return
      }
      if (context === INCOMING_CALL_CONTEXT) {
        currentNotification?.invite
          .accept({
            audio: getPreferredMicrophoneDeviceForDialing(),
            rootElement: rootMcuRef.current,
            video: getPreferredVideoDeviceForDialing(),
          })
          .then(roomSession => {
            void startRoomSession(roomSession)
          })
          .catch(() => {
            handleErrorDialog()
          })
      } else {
        await joinRoom({
          channel: channel,
          context: context,
          name: name,
          redirect: redirect,
          rootElement: rootMcuRef.current,
        }).catch(() => {
          handleErrorDialog()
        })
      }
    },
    [
      channel,
      currentNotification,
      getPreferredMicrophoneDeviceForDialing,
      getPreferredVideoDeviceForDialing,
      handleErrorDialog,
      joinRoom,
      name,
      redirect,
      rootMcuRef,
      startRoomSession,
    ],
  )

  useEffect(() => {
    // Run only once per room name so that the room is not rejoined
    const initialized = lastRoomName !== null && name === lastRoomName.current
    lastRoomName.current = name

    if (initialized || !rootMcuRef.current || memberState !== 'ready') return

    if (!context || !name) {
      console.error('Error: A parameter was missing when entering the Room')
      handleErrorDialog()
      return
    }

    void joinThisRoom(context)
  }, [context, handleErrorDialog, joinThisRoom, memberState, name, rootMcuRef])

  const handleCancel = async () => {
    // FIXME: leave and ensure the room is cleaned up and the room session is ended
  }

  if (!context?.trim() || !name?.trim()) {
    // TODO: error message displayed in video area?
    return null
  }

  return (
    <>
      <Feature name={FEATURES.ROOM_LOADING_UI}>
        {memberState !== 'joined' && (
          <div className="mt-[-28px] flex h-full w-full flex-col items-center justify-center overflow-hidden bg-primary">
            <CardLoading
              cardTitle="Connecting"
              cardDescription={`Joining ${context}/${name}`}
              // TODO: add cancel label after implementing handleCancel
              confirmLabel=""
              onConfirm={handleCancel}
              open
            />
          </div>
        )}
      </Feature>

      {/* Video Call */}
      <div
        className={cn('h-full', {
          hidden: isAudioOnly || memberState !== 'joined',
        })}
      >
        <MemberOverlays />
        <div ref={rootMcuRef} />
      </div>

      {/* Audio Call */}
      <div
        className={cn('flex h-full flex-col', {
          hidden: !isAudioOnly || memberState !== 'joined',
        })}
      >
        <AudioCallControls redirect={redirect} />
      </div>
    </>
  )
}
