import { Icon } from '@/components/common/icons/Icon'
import { REDIRECT_PATHS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { cn } from '@/helpers/utils'
import { useRouter } from '@tanstack/react-router'
import type { PropsWithChildren } from 'react'

type LinkLeaveProps = PropsWithChildren<{
  className?: string
  to?: RedirectPath | undefined
}>

export const LinkLeave = ({
  className,
  children,
  to = REDIRECT_PATHS.RECENT,
}: LinkLeaveProps) => {
  
  const { navigate } = useRouter()
  const leaveHandler = async () => {
    await navigate({ to })
  }

  return (
    <div onClick={leaveHandler}>
      {/* CSS delay adds hover intent */}
      <div
        className={cn(
          'm-0 flex h-20 w-20 flex-col items-center justify-center gap-y-1 rounded-full bg-background p-0 text-destructive transition-colors hover:bg-destructive hover:text-background hover:delay-button asLink',
          className,
        )}
      >
        <Icon size="xl" tag="phone-disabled" />
        <span>{children}</span>
      </div>
    </div>
  )
}
