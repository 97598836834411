import type { ButtonBaseRoomProps } from '@/components/ButtonBaseRoom'
import { ButtonBaseRoom } from '@/components/ButtonBaseRoom'
import { cn } from '@/helpers/utils'
import type { PropsWithChildren } from 'react'

type ButtonVideoRoomProps = Omit<ButtonBaseRoomProps, 'iconClassName'>

const ButtonVideoRoom = ({
  isActive = false,
  className,
  children,
  ...props
}: PropsWithChildren<ButtonVideoRoomProps>) => (
  <ButtonBaseRoom
    className={cn(
      'm-0 flex h-auto flex-col items-center rounded-none bg-none p-0 px-2 font-semibold text-neutral-light',
      className,
    )}
    isActive={isActive}
    iconClassName={cn('rounded-2xl px-5 py-1', isActive && 'gradient')}
    {...props}
  >
    {children}
    <span className="sr-only">&nbsp;{isActive ? 'active' : 'inactive'}</span>
  </ButtonBaseRoom>
)

export { ButtonVideoRoom }
export type { ButtonVideoRoomProps }
