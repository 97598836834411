import { createFileRoute } from '@tanstack/react-router'
import { RoomView } from '@/components/views/RoomView'
import {
  roomBeforeLoad,
  roomLoader,
  roomOnLeave,
  roomSearchSchema,
} from '@/helpers/room'

/* eslint-disable sort-keys */
// Properties must be in order as defined by TanStack Router
// See: https://tanstack.com/router/latest/docs/eslint/create-route-property-order
export const Route = createFileRoute('/_auth/room/$context/$name')({
  // TODO: format the room title
  component: RoomView,
  loaderDeps: ({ search: { channel } }) => ({
    channel,
  }),
  beforeLoad: ({ params: { context, name } }) => {
    // TODO: Move this function back from helpers?
    return roomBeforeLoad({ context, name })
  },
  loader: ({ params, deps: { channel } }) => {
    return roomLoader({ channel, name: params.name })
  },
  onLeave: _params => {

    roomOnLeave()
  },
  validateSearch: roomSearchSchema,
})
/* eslint-enable sort-keys */
