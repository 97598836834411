import { AudioCallControls } from '@/components/AudioCallControls'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'

export const CallsView = () => {
  return (
    <div className="flex h-full flex-col">
      <section>
        <div className="flex flex-col gap-y-1 pb-1 pl-6 pr-5 pt-2">
          <div className="flex items-center justify-between">
            <h2 className="text-4xl font-semibold leading-normal">Calls</h2>
            <Button
              className="bg-background p-0 text-foreground"
              disabled
              variant="icon"
            >
              {/* TODO: use dialpad icon */}
              <span className="sr-only">Dialpad</span>
              <Icon tag="more-horiz" size="xl" variant="foreground" />
            </Button>
          </div>
        </div>
      </section>
      <AudioCallControls />
    </div>
  )
}
