import type { ButtonBaseRoomProps } from '@/components/ButtonBaseRoom'
import { ButtonBaseRoom } from '@/components/ButtonBaseRoom'
import type { PropsWithChildren } from 'react'

type ButtonAudioRoomProps = Omit<ButtonBaseRoomProps, 'iconClassName'>

const ButtonAudioRoom = ({
  children,
  ...props
}: PropsWithChildren<ButtonAudioRoomProps>) => (
  <ButtonBaseRoom variant="rounded" {...props}>
    {children}
  </ButtonBaseRoom>
)

export { ButtonAudioRoom }
