import { z } from 'zod'
import { useDevicesStore } from '@/stores/devices'
import { useResourcesStore } from '@/stores/resources'
import { useChatStore } from '@/stores/chat'
import { useRoomStore } from '@/stores/room'
import { CHANNEL_TYPE } from '@/helpers/constants'
import type { RedirectPaths } from '@/helpers/types'

const ATTEMPT_COUNT_MAX = 6
const ATTEMPT_DELAY_MS = 500

const REDIRECT_PATHS = {
  CALLS: '/calls',
  CHATS: '/chats',
  DIRECTORY: '/directory',
  RECENT: '/recent',
  ROOMS: '/rooms',
} satisfies RedirectPaths

export const roomSearchSchema = z.object({
  channel: z
    .enum([CHANNEL_TYPE.AUDIO, CHANNEL_TYPE.VIDEO])
    .optional()
    .catch(error => {
      console.error('Invalid channel provided', error)
      // fallback to video channel
      return CHANNEL_TYPE.AUDIO
    }),
  redirect: z
    .enum([
      REDIRECT_PATHS.CALLS,
      REDIRECT_PATHS.CHATS,
      REDIRECT_PATHS.DIRECTORY,
      REDIRECT_PATHS.RECENT,
      REDIRECT_PATHS.ROOMS,
    ])
    .optional()
    .catch(error => {
      console.error('Invalid redirect path provided', error)
      // fallback to recent path
      return REDIRECT_PATHS.RECENT
    }),
})

let beforeLoadTimeoutId: NodeJS.Timeout

interface roomBeforeLoadParams {
  context: string
  name: string
}
export const roomBeforeLoad = ({ context, name }: roomBeforeLoadParams) => {
  const routeContext = {
    title: `${context}/${name}`,
  }
  let attempts = 0

  return new Promise((resolve, reject) => {
    const waitForUnload = () => {
      const { memberState } = useRoomStore.getState()
      // Wait for previous room to clear
      if (memberState === 'ready') {
        resolve(routeContext)
      } else {
        attempts++
        if (attempts >= ATTEMPT_COUNT_MAX) {
          reject(
            new Error(
              `The last room did not clear in ${attempts * ATTEMPT_DELAY_MS}ms.`,
            ),
          )
        } else {
          // Schedule next attempt
          beforeLoadTimeoutId = setTimeout(waitForUnload, ATTEMPT_DELAY_MS)
        }
      }
    }

    waitForUnload()
  })
}

interface roomLoaderParams {
  channel: string | undefined
  name: string
}
export const roomLoader = ({ channel, name }: roomLoaderParams) => {
  const { refreshCameraList, refreshMicrophoneList, refreshSpeakerList } =
    useDevicesStore.getState().actions
  const { fetchResource } = useResourcesStore.getState().actions

  // Refresh devices but do not refresh the camera list if the channel is 'audio'
  const devicePromises = [refreshMicrophoneList(), refreshSpeakerList()]
  if (channel !== CHANNEL_TYPE.AUDIO) {
    devicePromises.push(refreshCameraList())
  }
  void Promise.all(devicePromises)

  // Fetch the resource by the name to get room's chat conversations
  void fetchResource({ name }).then(({ name, id }) => {
    const { setAddressId, setRoomName } = useRoomStore.getState().actions
    const { getChatMessages, subscribeToChatMessages } =
      useChatStore.getState().actions

    // Set the room info in the store
    setRoomName(name)
    setAddressId(id)

    // subscribe and fetch initial chat messages and page cursors (if exists)
    subscribeToChatMessages({
      addressId: id,
      onMessage: message => console.log('Received new chat message:', message),
    }).catch(error => {
      console.error('Error subscribing to chat messages:', error)
    })

    getChatMessages({ addressId: id }).catch(error => {
      console.error('Error fetching chat messages:', error)
    })
  })
}

export const roomOnLeave = () => {
  const { addressId } = useRoomStore.getState()
  const { leaveRoom } = useRoomStore.getState().actions

  if (beforeLoadTimeoutId) {
    clearTimeout(beforeLoadTimeoutId)
  }

  // Clean up the room
  void leaveRoom().then(() => {
    // Unsubscribe from chat messages
    useChatStore.getState().actions.unsubscribeChatMessages(addressId)
  })
}
