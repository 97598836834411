import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useEffect } from 'react'

interface TimerProps {
  startTimer: boolean
}

const paddedWithZero = (num: number) => num.toString().padStart(2, '0')

const formatTimerFromSeconds = (seconds: number) => {
  const hoursElapsed = Math.floor(seconds / 3600)
  const minutesElapsed = Math.floor((seconds % 3600) / 60)
  const secondsElapsed = seconds % 60

  const paddedMinutes = paddedWithZero(minutesElapsed)
  const paddedSeconds = paddedWithZero(secondsElapsed)

  // as HH:MM:SS or MM:SS
  if (hoursElapsed > 0) {
    return `${paddedWithZero(hoursElapsed)}:${paddedMinutes}:${paddedSeconds}`
  } else {
    return `${paddedMinutes}:${paddedSeconds}`
  }
}

export const RoomSessionTimer = ({ startTimer }: TimerProps) => {
  const elapsedTimeSecs = useRoomStore(state => state.elapsedTimeSecs)
  const { incrementElapsedTime, resetElapsedTime } = useRoomStoreActions()

  // start the timer
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (startTimer) {
      interval = setInterval(() => {
        incrementElapsedTime()
      }, 1000)
    }

    // cleanup
    return () => {
      clearInterval(interval)
      resetElapsedTime()
    }
  }, [incrementElapsedTime, resetElapsedTime, startTimer])

  return (
    <div role="timer" className="text-2xl font-semibold text-background">
      {formatTimerFromSeconds(elapsedTimeSecs)}
    </div>
  )
}
