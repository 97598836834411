import { ButtonAudioRoom } from '@/components/ButtonAudioRoom'
import { LinkLeave } from '@/components/LinkLeave'
import { RoomSessionTimer } from '@/components/RoomSessionTimer'
import type { RedirectPath } from '@/helpers/types'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

interface AudioCallControlsProps {
  redirect?: RedirectPath | undefined
}

export const AudioCallControls = ({ redirect }: AudioCallControlsProps) => {
  const audioMuted = useRoomStore(state => state.audioMuted)
  const memberState = useRoomStore(state => state.memberState)

  const { audioMuteHandler } = useRoomStoreActions()

  // TODO: Implement switch to video
  const handleSwitchToVideo = () => {
    console.log('Switch to video')
  }

  const memberJoined = memberState === 'joined'

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-y-20 bg-primary">
      <RoomSessionTimer startTimer />

      <menu aria-label="Call Controls" className="flex flex-col">
        <li>
          <ul className="flex gap-8">
            <li>
              <ButtonAudioRoom
                disabled={!memberJoined}
                isActive={audioMuted}
                onClick={audioMuteHandler}
                tag={audioMuted ? 'mic-off-2' : 'mic'}
              >
                <span className="text-xs">
                  {audioMuted ? 'Unmute' : 'Mute'}
                </span>{' '}
                <span className="sr-only">Microphone</span>
              </ButtonAudioRoom>
            </li>
            <li>
              <ButtonAudioRoom
                disabled
                isActive={false}
                tag="videocam"
                onClick={handleSwitchToVideo}
              >
                <span className="text-xs">Switch</span>{' '}
                <span className="sr-only">to Video</span>
              </ButtonAudioRoom>
            </li>
          </ul>
        </li>
        <li className="flex items-center justify-center py-20">
          <LinkLeave to={redirect}>
            <span className="text-xs">End Call</span>
          </LinkLeave>
        </li>
      </menu>
    </div>
  )
}
